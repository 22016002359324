import { UtilsString } from './utils-string';
import { VueInstanceService } from '@/shared/services/vue-instance-service';
export class UtilsNotify {

    public static notificaciones: number = 0;
    

    public static NotificacionSuccess(title: string, texto: string) {
        UtilsNotify.Notificacion(title, texto, "#149E91", "check_circle");
    }
    public static NotificacionPrimary(title: string, texto: string, icon: string = '  ') {
        if (UtilsString.IsNullOrWhiteSpace(icon)) {
            icon = "verified_user";
        }
        UtilsNotify.Notificacion(title, texto, "primary", icon);
    }
    public static NotificacionDanger(title: string, texto: string) {
        UtilsNotify.Notificacion(title, texto, "danger", "error");
    }
    public static NotificacionWarning(title: string, texto: string) {
        UtilsNotify.Notificacion(title, texto, "warning", "warning");
    }
    public static getRandomInt(min: number, max: number) {
        return Math.floor(Math.random() * (max - min)) + min;
    }
    public static NotificacionRandomColor(title: string, texto: string, icon: string = '  ') {
        if (UtilsString.IsNullOrWhiteSpace(icon)) {
            icon = "insert_emoticon";
        }
        let color = `rgb(${UtilsNotify.getRandomInt(0, 255)},${UtilsNotify.getRandomInt(0, 255)},${UtilsNotify.getRandomInt(0, 255)})`
        UtilsNotify.Notificacion(title, texto, color, icon);
    }
    public static async Notificacion(title: string, texto: string, color: string, icon: string = '  ') {
        //@ts-ignore
        VueInstanceService.vueinstance.$vs.notify({
            flat: true,
            progress: 'auto',
            icon: UtilsString.IsNullOrWhiteSpace(icon) ? '' : icon,
            color: color,
            title: title,
            text: texto
        });
    }

    public TestearNotificaciones() {
        if (UtilsNotify.notificaciones > 5) {
            UtilsNotify.notificaciones = 0;
        }
        let titulo = "Cita guardada";
        let texto = "Su cita se ha guardado correctamente";
        switch (UtilsNotify.notificaciones) {
            case 0:
                UtilsNotify.NotificacionSuccess(titulo, texto);
                break;
            case 1:
                UtilsNotify.NotificacionPrimary(titulo, texto);
                break;
            case 2:
                UtilsNotify.NotificacionDanger(titulo, texto);
                break;
            case 3:
                UtilsNotify.NotificacionWarning(titulo, texto);
                break;
            case 4:
                UtilsNotify.NotificacionRandomColor(titulo, texto);
                break;
            case 5:
                UtilsNotify.Notificacion(titulo, texto, "white");
                break;
        }
        UtilsNotify.notificaciones++;
    }
}