import { VueInstanceService } from '@/shared/services/vue-instance-service';
import HttpService, { ssmHttpService } from '@/shared/services/http-service';

export class UtilsEncript {

    public static key: string = "qwefbjhioqwbfhiqwbfhiwehi";
    public static keySize = 256;
    public static ivSize = 128;
    public static iterations = 100;

    public static Encriptar(Texto: any): string {
        //return Componente.CryptoJS.AES.encrypt(Texto, "Secret Passphrase").toString()

        return UtilsEncript.encrypt(VueInstanceService.vueinstance, Texto);
    }
    public static encrypt(Componente: any, msg: any): string {
        var salt = Componente.CryptoJS.lib.WordArray.random(128 / 8);

        var key = Componente.CryptoJS.PBKDF2(UtilsEncript.key, salt, {
            keySize: UtilsEncript.keySize / 32,
            iterations: UtilsEncript.iterations
        });

        var iv = Componente.CryptoJS.lib.WordArray.random(128 / 8);

        var encrypted = Componente.CryptoJS.AES.encrypt(msg, key, {
            iv: iv,
            padding: Componente.CryptoJS.pad.Pkcs7,
            mode: Componente.CryptoJS.mode.CBC

        });

        // salt, iv will be hex 32 in length
        // append them to the ciphertext for use  in decryption
        var transitmessage = salt.toString() + iv.toString() + encrypted.toString();
        return transitmessage;
    }

    public static decrypt(Componente: any, Texto: any) {
        var salt = Componente.CryptoJS.enc.Hex.parse(Texto.substr(0, 32));
        var iv = Componente.CryptoJS.enc.Hex.parse(Texto.substr(32, 32))
        var encrypted = Texto.substring(64);

        var key = Componente.CryptoJS.PBKDF2(UtilsEncript.key, salt, {
            keySize: UtilsEncript.keySize / 32,
            iterations: UtilsEncript.iterations
        });

        var decrypted = Componente.CryptoJS.AES.decrypt(encrypted, key, {
            iv: iv,
            padding: Componente.CryptoJS.pad.Pkcs7,
            mode: Componente.CryptoJS.mode.CBC

        })
        return decrypted.toString(Componente.CryptoJS.enc.Utf8);
    }

    public static DesEncriptar(Texto: any): string {
        //return Componente.CryptoJS.AES.decrypt(Texto, "Secret Passphrase").toString()
        return UtilsEncript.decrypt(VueInstanceService.vueinstance, Texto);
    }

    public static async TestWalla() {
        //Metodo de encripcion que utiliza wallop
        let Timestamp = (new Date).getTime().toString();
        let Componente: any = VueInstanceService.vueinstance;
        let i = Componente.CryptoJS;
        let ruta_accesOapi = '/api/v3/items/3zlgpd2048jx';
        let r = "/api/v3/items/3zlgpd2048jx+#+PUT+#+" + Timestamp + "+#+";
        let base = i.enc.Base64.parse("UTI5dVozSmhkSE1zSUhsdmRTZDJaU0JtYjNWdVpDQnBkQ0VnUVhKbElIbHZkU0J5WldGa2VTQjBieUJxYjJsdUlIVnpQeUJxYjJKelFIZGhiR3hoY0c5d0xtTnZiUT09");
        let h256 = i.HmacSHA256(r, base);
        let d = i.enc.Base64.stringify(h256);

        let s = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer JsmMPT52v92P30LVqShe23hTak6zwnUyY9rXHOSfZhZaLQjItKBPvB3hcIYN3Ob3gskxqU',
            'Timestamp': Timestamp,
            'X-Signature': d,
            'X-DeviceOS': '0',
            'Accept':'application/json, text/plain, */*'
        };
        ssmHttpService.getAxiosInstance().defaults.headers = s;
        let json = '{"id":"3zlgpd2048jx","category_id":"13200","title":"aplicaciones","sale_price":0,"currency_code":"EUR","description":"Mejora tu negocio facilitandote las tareas. piensa en el futuro y haz tu aplicaciÃ³n o app. pregunta sin compromiso...","sale_conditions":{"fix_price":false,"exchange_allowed":false,"shipping_allowed":false},"delivery_info":null,"location":{"address":"46230, Alginet","latitude":39.28229072841324,"longitude":-0.5063203040415152},"extra_info":{"condition":null}}';
        let response = await ssmHttpService.put('https://api.wallapop.com/api/v3/items/3zlgpd2048jx', json, false, true);
        response = response;
    }
}