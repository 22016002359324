import { getModule, Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { store } from '@/store/store';
import { API } from '@/shared/api';
import { paciente } from '@/shared/dtos/paciente-dto';
import { ssmHttpService } from '@/shared/services/http-service';
import { UtilsNotify } from '@/utils/utils-notify';
import { UtilsString } from '@/utils/utils-string';
import { etiqueta_paciente } from '@/shared/dtos/etiqueta_paciente';

@Module({
    namespaced: true,
    name: 'pacienteModule',
    store,
    dynamic: true,
})

class pacientesModule extends VuexModule {
    public pacientes: paciente[] = [];
    public paciente: paciente = new paciente();

    @Action({ commit: 'onGetpacientes' })
    public async getpacientes() {
        return await ssmHttpService.get(API.paciente, null, true, false, true);
    }
    @Action({ commit: 'onGetpaciente' })
    public async getpaciente(paciente: paciente) {
        return await ssmHttpService.get(API.paciente + '/' + paciente.numero, null, true, false, true);
    }
    @Action({ commit: 'onGetpaciente' })
    public async getpacienteById(id:number) {
        return await ssmHttpService.get(API.paciente + '/' + id, null, true, false, true);
    }

    @Action({ commit: 'onGetpacientes' })
    public async getpacientesback() {
        return await ssmHttpService.get(API.paciente, null, false);
    }
    @Action({})
    public async send_email_acceso_app(paciente: paciente) {
        return await ssmHttpService.get(API.paciente+'/send_email_acceso_app/'+paciente.numero, null, false);
    }
    @Action({ commit: 'onAddpaciente' })
    public async guardarpaciente(paciente: paciente) {
        await ssmHttpService.post(API.paciente, paciente.toJson());
        this.getpacientesback();
    }
    @Action({ commit: 'onUpdatepaciente' })
    public async actualizarpaciente(paciente: paciente) {
        await ssmHttpService.put(API.paciente + '/' + paciente.numero, paciente);
        this.getpacientesback();
    }
    @Action({ commit: 'onDeletepaciente' })
    public async eliminarpaciente(paciente: paciente) {
        await ssmHttpService.delete(API.paciente + '/' + paciente.numero, null, false);
        this.getpacientesback();
    }

    @Action({ commit: 'onGetpaciente' })
    public async searchpaciente(id: number) {
        for (let i = 0; i < this.pacientes.length; i++) {
            const element = this.pacientes[i];
            if (element.numero === id) {
                return element;
            }
        }
    }

    @Mutation
    public onGetpacientes(res: paciente[]) {
        this.pacientes = res ? res.map((x) => new paciente(x)) : [];
    }
    @Mutation
    public onGetpaciente(res: paciente) {
        this.paciente = new paciente(res);
        this.paciente.Pac_Etivuetify = [];

        for (let i = 0; i < this.paciente.Pac_EtiDto.length; i++) {
            this.paciente.Pac_Etivuetify.push({
                text:
                    UtilsString.ValueOf(this.paciente.Pac_EtiDto[i].nombre_etiqueta),
                id: this.paciente.Pac_EtiDto[i].id_etiqueta,
            }
            );
        }
    }

    @Mutation
    public onAddpaciente(res: paciente) {
        UtilsNotify.NotificacionSuccess(
            "Paciente añadido",
            "El paciente se ha añadido correctamente"
        );
    }

    @Mutation
    public onUpdatepaciente(res: paciente) {
        UtilsNotify.NotificacionSuccess(
            "Paciente actualizado",
            "El paciente se ha actualizado correctamente"
        );
    }

    @Mutation
    public onDeletepaciente(res: paciente) {
        UtilsNotify.NotificacionDanger(
            "Paciente eliminado",
            "El paciente se ha eliminado correctamente"
        );
    }

}

export default getModule(pacientesModule);
