import { store } from '@/store/store';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { EmailDto } from '@/shared/dtos/Email-dto';

@Module({
    namespaced: true,
    name: 'EmailsModule',
    store,
    dynamic: true
})
class EmailsModule extends VuexModule {
    public EmailTemplate: string = "";

    @Action({ commit: 'onGetEmail' })
    public async GetEmail() {
        return await ssmHttpService.get(API.Emails);
    }
    @Action({})
    public async SendEmail(email: EmailDto) {
        return await ssmHttpService.post(API.Emails, email.toJson());
    }
    @Action({})
    public async SendEmailSoporte(email: EmailDto) {
        return await ssmHttpService.post(API.Emails + '/send_email_soporte', email.toJson());
    }

    @Action({})
    public async SendEmailSugerencia(email: EmailDto) {
        return await ssmHttpService.post(API.Emails + '/sugerencia', email.toJson());
    }
    @Action({})
    public async SendNuevaVersion() {
        return await ssmHttpService.get(API.Emails + '/send_new_version');
    }
    

    @Action({})
    public async SendEmailTestMarketing(id_email: number) {
        return await ssmHttpService.get(API.Emails + '/test_email/' + id_email);
    }

    @Mutation
    public onGetEmail(res: string) {
        this.EmailTemplate = res;
    }

}
export default getModule(EmailsModule);