

























import { Component, Vue } from "vue-property-decorator";
import EmailsModule from "@/store/modules/Emails/Emails-module";
import { EmailDto } from "@/shared/dtos/Email-dto";
import { UtilsPaciente } from "@/utils/utils-paciente";
import { UtilsString } from "@/utils/utils-string";
import { paciente } from "@/shared/dtos/paciente-dto";
import { UtilsEncript } from "@/utils/utils-encript";
import pacienteModule from "@/store/modules/paciente-module";
import { RouterNames } from "@/router/routernames";
import { UtilsNotify } from "@/utils/utils-notify";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue")
  }
})
export default class EnviarMailPaciente extends Vue {
  public email: EmailDto = new EmailDto();
  public paciente: paciente = new paciente();
  public async created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id)) {
      this.paciente.numero = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id)
      );
      await pacienteModule.getpaciente(this.paciente);
      this.paciente = pacienteModule.paciente;
      this.email.email = this.paciente.email;
    }
    if (UtilsString.IsNullOrWhiteSpace(this.paciente.email)) {
      this.$router.push({ name: RouterNames.pacienteslista });
    }
  }
  public EnviarMail() {
    EmailsModule.SendEmail(this.email);
    this.$router.push({ name: RouterNames.pacienteslista });
    UtilsNotify.NotificacionSuccess(
      "Email",
      "El email se ha enviado correctamente"
    );
  }

  public get ImagenPaciente() {
    return UtilsPaciente.getrutaImagen(this.paciente);
  }

  public get getNombre() {
    return UtilsPaciente.getNameApellidos(this.paciente);
  }
}
