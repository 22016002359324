import { appointmentCalendar } from '@/shared/dtos/appointment-calendar';
import { paciente } from '@/shared/dtos/paciente-dto';
import publicPathModule from '@/store/modules/public-path-module';
import { UtilsString } from './utils-string';

export class UtilsPaciente {
    public static getNameApellidos(paciente: paciente) {

        return UtilsString.ValueOf(paciente.nombre) + ' ' + UtilsString.ValueOf(paciente.apellidos);
    }

    public static getrutaImagen(paciente: paciente) {
        let imagen = publicPathModule.publicPath + "assets/images/boy-my-medical-diet.svg";
        try {
            if (paciente.sexo === "Mujer") {
                imagen = publicPathModule.publicPath + "assets/images/girl-my-medical-diet.svg";
            }
        } catch (error) { }
        return imagen;
    }
    public static getrutaImagenFromString(sexo: any) {
        let imagen = publicPathModule.publicPath + "assets/images/boy-my-medical-diet.svg";
        try {
            sexo = UtilsString.ValueOf(sexo);
            if (sexo === "Mujer") {
                imagen = publicPathModule.publicPath + "assets/images/girl-my-medical-diet.svg";
            }
        } catch (error) { }
        return imagen;
    }
    public static getsexoFromObject(object: any):string {
        let imagen = publicPathModule.publicPath + "assets/images/boy-my-medical-diet.svg";
        try {
            object.sexo = UtilsString.ValueOf(object.sexo);
            if (object.sexo === "Mujer") {
                imagen = publicPathModule.publicPath + "assets/images/girl-my-medical-diet.svg";
            }
        } catch (error) { }
        return imagen;
    }
    public static getrutaImagenFromAppoitmentCalendar(appointment: appointmentCalendar) {

        let imagen = publicPathModule.publicPath + "assets/images/boy-my-medical-diet.svg";
        if (appointment === undefined) {
            return imagen;
        }
        if (appointment.metadata === undefined) {
            return imagen;
        }
        if (appointment.metadata.Paciente === undefined) {
            return imagen
        }
        let paciente = appointment.metadata.Paciente;
        try {
            if (paciente.sexo === "Mujer") {
                imagen = publicPathModule.publicPath + "assets/images/girl-my-medical-diet.svg";
            }
        } catch (error) { }
        return imagen;
    }
}